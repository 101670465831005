import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Layout from "../../components/layout/layout";

const useStyles = makeStyles((theme) => ({
  paddingRegular: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
}));

const LicensePlateRecognition = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid
            item
            className={clsx({
              [classes.paddingRegular]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
          >
            <Typography variant="h4">LicensePlateRecognition</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default LicensePlateRecognition;

export const query = graphql`
  query LicensePlateRecognitionQueryEn {
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
